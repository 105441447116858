const authentication = require('react-azure-adb2c').default;


export const download = (values: any) => {

    // const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/Daicho/` + values.tatemono_id + '/' + values.room_id + '/' + values.section_id; //旧
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/NewDaicho/` + values.tatemono_id + '/' + values.room_id + '/1/' + values.section_id;

    // const url = 　`https://doc.homestation.jp` + `/api/Lifeline/` + values + '/A3';

    // const token = authentication.getAccessToken(); //あったら使いましょー

    // console.log(url)

    return fetch(url, {

        method: 'get',

        headers: { /*'Authorization': 'Bearer ' + token ,*/'Accept': 'application/pdf', },



    }).then(res => res.blob())

        .then(payload => ({ payload }))

        .catch(error => ({ error }));

}

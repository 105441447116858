import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { actionCreators } from '../../Store/MailData';
import * as Detail from './Saga/Detail';
import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';
import { reduxForm } from 'redux-form';
import './Css/Table.css';
import './Css/DetailCost.css';
import Button from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/Star';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Alert from '@material-ui/lab/Alert';
import { Card, CardContent } from '@material-ui/core';

// import * as AutoKana from 'vanilla-autokana';

const link = (str) => {
    const regexp_url = /https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+/g;
    const regexp_makeLink = (url) => {
        return `<a href=${url} target="_blank" rel="noopener noreferrer">${url}</a>`;
    }
    const replacedString = str.replace(/\r?\n/g, '<br />').replace(regexp_url, regexp_makeLink);

    return (
        replacedString
    )
};
class CostDisp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reflesh: false,
        };
        this.setFavorite = this.setFavorite.bind(this);
        this.daichoDownload = this.daichoDownload.bind(this);
    }

    setFavorite() {
        this.props.change_favorite_detail({ is_bookmark: this.props.search_rooms_detail.is_bookmark ? false : true, tatemono_id: this.props.search_rooms_detail.tatemono_id, room_id: this.props.search_rooms_detail.room_id, cd: this.props.cd.cd, no: this.props.cd.no })

    }

    daichoDownload() {
        this.props.daicho_download_docs({ tatemono_id: this.props.search_rooms_detail.tatemono_id, room_id: this.props.search_rooms_detail.room_id, section_id: this.props.search_rooms_detail.section_id/*, cd: this.props.cd.cd, no: this.props.cd.no */ })
    }

    render() {
        const isMobile = window.innerWidth <= 768;
        console.log(this.props.search_rooms_detail)
        return (
            <Card className={this.props.search_rooms_detail.kuushitsu_joukyou_no === 1 || this.props.disp_applied === 1 ? "back_color" : "back_color seiyakuZumi_color"}>
                <CardContent className="text_style">
                    <div className="row title_border">
                        {this.props.search_rooms_detail.kuushitsu_joukyou_no === 1 || this.props.disp_applied === 1 ? ""
                            :
                            <span className="label label-seiyakuZumi2">
                                <i className="fas fa-key"></i> 成約済み
                            </span>
                        }
                        {/* 前画面からの物件名 */}
                        {/* <div className="col-sm-12"><h3>{this.props.search_rooms_detail.tatemono_name} {this.props.search_rooms_detail.room_name} {this.props.search_rooms_detail.is_bookmark?"〇":"×"}</h3></div> */}
                        <div className="col-sm-12 detailtop">
                            <h3 style={{ display: "inline-block" }}>{this.props.search_rooms_detail.tatemono_name} {this.props.search_rooms_detail.room_name}</h3>
                            <div className='favdown'>
                                {this.props.cd.no === "line" || this.props.cd.no === "mail" ? ""
                                    :
                                    isMobile?<Button
                                        className='FavBtn'
                                        variant="outlined"
                                        value={this.props.search_rooms_detail.is_bookmark}
                                        onClick={this.setFavorite}
                                    >
                                        {<StarIcon
                                            style={this.props.search_rooms_detail.is_bookmark ? { color: "ff8c00" } : { color: "#d3d3d3" }}
                                        />}
                                        <p style={{ fontSize: "10px", color: "#808080", fontWeight: "bold" }}>{this.props.search_rooms_detail.is_bookmark ? "お気に入り" : "お気に入り登録"}</p>
                                    </Button>
                                    :""
                                }
                                {/* {this.props.download_flg ?
                                    <LoadingButton loading variant="outlined" className='DownBtn' onClick={this.daichoDownload}>Submit
                                        <p style={{ fontSize: "10px", color: "#808080", fontWeight: "bold", lineHeight: "normal" }}>物件資料<br></br>ダウンロード</p>
                                    </LoadingButton>
                                    :
                                    <Button
                                        className='DownBtn'
                                        variant="outlined"
                                        onClick={this.daichoDownload}
                                    >
                                        <DownloadIcon style={{ color: "#808080" }} /><p style={{ fontSize: "10px", color: "#808080", fontWeight: "bold", lineHeight: "normal" }}>物件資料<br></br>ダウンロード</p>
                                    </Button>
                                } */}
                                
                                <a
                                    className='DownBtn' 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    // href={
                                    //     process.env.REACT_APP_API_LARAVEL_API_URL + `/api/Daicho/` +
                                    //     this.props.search_rooms_detail.tatemono_id + '/' +
                                    //     this.props.search_rooms_detail.room_id + '/' +
                                    //     this.props.search_rooms_detail.section_id
                                    // }
                                    href={
                                        process.env.REACT_APP_API_LARAVEL_API_URL + `/api/NewDaicho/` +
                                        this.props.search_rooms_detail.tatemono_id + '/' +
                                        this.props.search_rooms_detail.room_id + '/1/' +
                                        this.props.search_rooms_detail.section_id
                                    }
                                >
                                    <DownloadIcon style={{ color: "#808080" }} /><p style={{ fontSize: "10px", color: "#808080", fontWeight: "bold", lineHeight: "normal" }}>物件資料<br></br>ダウンロード</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row title_border">
                        <div className="cost_style ml-2">
                            {/* カンマ表示 */}
                            <dl>
                                <dt className='cont_title'>家賃</dt>
                                <dd className='nedan nums' style={{ color: this.props.search_rooms_detail.kuushitsu_joukyou_no === 1 || this.props.disp_applied === 1 ? "" : "black", fontWeight: this.props.search_rooms_detail.kuushitsu_joukyou_no === 1 || this.props.disp_applied === 1 ? "" : "normal" }}>{this.props.search_rooms_detail.chinryou.toLocaleString()}円</dd>
                            </dl>
                            <dl>
                                <dt className='cont_title'>共益費</dt>
                                <dd className='nums'>{this.props.search_rooms_detail.kyouekihi ? this.props.search_rooms_detail.kyouekihi.toLocaleString() + "円" : "込み"}</dd>
                            </dl>
                            <dl>
                                <dt className='cont_title'>駐車場</dt>
                                <dd className='nums'>
                                    {this.props.search_rooms_detail.p_aki === "管理外" ? (this.props.search_rooms_detail.p_ryou === "" ? " - " : this.props.search_rooms_detail.p_ryou)
                                    : this.props.search_rooms_detail.p_aki === "空き無し" ? "満車" 
                                    : this.props.search_rooms_detail.p_aki === "空きあり" ? (this.props.search_rooms_detail.p_ryou === "" ? " - " : this.props.search_rooms_detail.p_ryou)
                                    :this.props.search_rooms_detail.p_aki=== "無し" ? "無し":""}
                                </dd>
                            </dl>
                            <dl>
                                <dt className='cont_title'>敷金</dt>
                                <dd className='nums'>{this.props.search_rooms_detail.shikikin_mon >= 1 ? this.props.search_rooms_detail.shikikin_mon + "ヶ月" : this.props.search_rooms_detail.shikikin > 0 ? this.props.search_rooms_detail.shikikin.toLocaleString() + "円" : "-"}</dd>
                            </dl>
                            <dl>
                                <dt className='cont_title'>礼金</dt>
                                <dd className='nums'>{this.props.search_rooms_detail.reikin_mon >= 1 ? this.props.search_rooms_detail.reikin_mon + "ヶ月" : this.props.search_rooms_detail.reikin > 0 ? this.props.search_rooms_detail.reikin.toLocaleString() + "円" : " - "}</dd>
                            </dl>
                            <dl>
                                <dt className='cont_title'>定額クリーニング</dt>
                                <dd className='nums'>{this.props.search_rooms_detail.teigaku_cleaning ? this.props.search_rooms_detail.teigaku_cleaning : " - "}</dd>
                            </dl>
                            
                        </div>
                        {this.props.search_rooms_detail.houjin[0].type === 1?<div className="cost_style ml-2" style={{marginTop:"10px"}}>
                            <dl>
                                <dt className='cont_title'>法人契約の場合</dt>
                                <dd className='nums'>{this.props.search_rooms_detail.houjin_keiyaku}</dd>
                            </dl>
                        </div>:""}
                    </div>
                    {/*<div className={this.props.search_rooms_detail.remarks === null ? "row" : "row title_border"}>
                        <div className="deposit_style ml-2">
                            <span>敷金 {this.props.search_rooms_detail.shikikin_mon >= 1 ? this.props.search_rooms_detail.shikikin_mon + "ヶ月　/　" : this.props.search_rooms_detail.shikikin > 0 ? this.props.search_rooms_detail.shikikin.toLocaleString() + "円　/　" : " -　/　"}</span>
                            <span>礼金 {this.props.search_rooms_detail.reikin_mon >= 1 ? this.props.search_rooms_detail.reikin_mon + "ヶ月" : this.props.search_rooms_detail.reikin > 0 ? this.props.search_rooms_detail.reikin.toLocaleString() + "円" : " - "}</span>
                             <span>敷金 {this.props.search_rooms_detail.shikikin_mon >= 1 ? this.props.search_rooms_detail.shikikin_mon + "ヶ月　/　" : this.props.search_rooms_detail.shikikin_mon === null ? " -　/　" : this.props.search_rooms_detail.shikikin.toLocaleString() + "円　/　"}</span>
                            <span>礼金 {this.props.search_rooms_detail.reikin_mon >= 1 ? this.props.search_rooms_detail.reikin_mon + "ヶ月" : this.props.search_rooms_detail.reikin_mon === null ? " - " : this.props.search_rooms_detail.reikin.toLocaleString() + "円"}</span> 
                        </div>
                    </div>*/}

                    
                    {/* {this.props.search_rooms_detail.remarks ? <div className="row">
                        <Alert severity="error" icon={<ChatBubbleOutlineIcon fontSize="inherit" />} style={{ textAlign: "left", width: "100%" }}><span style={{ fontSize: "16px", fontWeight: "bold" }}>スタッフコメント</span><br></br><span style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: link(this.props.search_rooms_detail.remarks) }}></span></Alert>
                    </div> : ""
                    } */}
                    
                </CardContent>
            </Card>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
        cd: state.masterReducer.cd,
        download_flg: state.Detail.download_flg,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_favorite_detail(state) {
            dispatch(Detail.change_favorite_detail(state))
        },
        daicho_download_docs(state) {
            dispatch(Detail.daicho_download_docs(state))
        },

        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

CostDisp = reduxForm({
    form: 'roomform', // a unique identifier for this form
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(CostDisp)

export default connect(mapStateToProps, mapDispatchToProps)(CostDisp);
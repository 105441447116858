import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Reserve from './Saga/Reserve';
// import * as Reserve from './Saga/Reserve';

import "react-datepicker/dist/react-datepicker.css";

// import { Field, reduxForm } from 'redux-form';
import './Css/TimeStateComponent.css';
import './Css/Reserve.css';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
// let hiddenFlg;

// const StateClasses = ["open","crowded","closed"];   //〇：受付中、△：残りわずか、×：受付終了

class TimeStateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,
        };
        this.reserverInfo = this.reserverInfo.bind(this);
    }

    reserverInfo() {
        this.props.change_reserve_date({ date: this.props.date, time: this.props.time, flg: this.props.flg })
    }

    render() {
        // console.log(this.props.status,"this.props.status")
        // console.log(moment(this.props.date).format("YYYYMMDD"),"this.props.date")
        // console.log(this.props.time,"this.props.time")

        // 現在の時間より前は予約できないようにする
        var today = new Date();
        let times = this.props.time === 1 ? 10 : this.props.time === 2 ? 13 : 15;
        let ToDay = false;
        if(today.getFullYear() === this.props.date.getFullYear() && today.getMonth() === this.props.date.getMonth() && today.getDate() === this.props.date.getDate() && today.getHours() >= times){
            ToDay = true
        }

        console.log(this.props.time,today.getHours(),"時間")
        console.log(times,ToDay,"ToDay")
        
        return (
            // 2024/1/4の10時以外予約不可追加(2023.12.11)
            moment(this.props.date).format("YYYYMMDD") < moment().format("YYYYMMDD") || (moment(this.props.date).format("YYYYMMDD") === "20250108" && (this.props.time === 2 || this.props.time === 3)) ? 
                <div className="reserve_cell" style={{ backgroundColor: (this.props.reserve_date && (this.props.date.getMonth() === this.props.reserve_date.date.getMonth() && this.props.date.getDate() === this.props.reserve_date.date.getDate() && this.props.time === this.props.reserve_date.time)) ? "#ffe4e1" : "" }}>
                    <ClearIcon style={{ color: "#a9a9a9" }} />
                </div>// ×
                : ToDay ?
                <div className="reserve_cell" style={{ backgroundColor: (this.props.reserve_date && (this.props.date.getMonth() === this.props.reserve_date.date.getMonth() && this.props.date.getDate() === this.props.reserve_date.date.getDate() && this.props.time === this.props.reserve_date.time)) ? "#ffe4e1" : "" }}>
                    <ClearIcon style={{ color: "#a9a9a9" }} />
                </div>// ×
                : this.props.status === -1 ? // ×
                    <div className="reserve_cell" style={{ backgroundColor: (this.props.reserve_date && (this.props.date.getMonth() === this.props.reserve_date.date.getMonth() && this.props.date.getDate() === this.props.reserve_date.date.getDate() && this.props.time === this.props.reserve_date.time)) ? "#ffe4e1" : "" }}>
                        <ClearIcon style={{ color: "#a9a9a9" }} />
                    </div>
                    : this.props.status <= 1 ? //〇
                        <div className="reserve_cell mouse_over" style={{ backgroundColor: (this.props.reserve_date && (this.props.date.getMonth() === this.props.reserve_date.date.getMonth() && this.props.date.getDate() === this.props.reserve_date.date.getDate() && this.props.time === this.props.reserve_date.time)) ? "#ffe4e1" : "" }}>
                            <div onClick={this.reserverInfo}><RadioButtonUncheckedIcon style={{ color: "#6996e0" }} /></div>
                        </div>
                        : (this.props.status >= 2) ? //　△
                            <div className="reserve_cell mouse_over" style={{ backgroundColor: (this.props.reserve_date && (this.props.date.getMonth() === this.props.reserve_date.date.getMonth() && this.props.date.getDate() === this.props.reserve_date.date.getDate() && this.props.time === this.props.reserve_date.time)) ? "#ffe4e1" : "" }}>
                                <div onClick={this.reserverInfo}><ChangeHistoryIcon style={{ color: "#ffcc00" }} /></div>
                            </div>

                            :
                            ""
        );
    }
}



//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        reserve_date: state.Reserve.reserve_date,
        // schedule: state.Reserve.schedule,

        // setsubi_master: state.roomsReducer.setsubi_master,

        // chomei_list: state.search.chomei_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_reserve_date(state) {
            dispatch(Reserve.change_reserve_date(state))
        }

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(TimeStateComponent);